import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from './index.module.scss'

interface Props {
  title?: string
  text: string
}

export default function InfoIcon({ title, text }: Props) {
  return (
    <span className={styles.infoInco_wrapper}>
      <FontAwesomeIcon
        icon={faQuestionCircle}
        className={styles.icon_question}
      />
      <div className={styles.info_container}>
        <div className={styles.triangle}></div>
        <div className={styles.info_container_wrapper}>
          <div className={styles.title}>{title}</div>
          <div>{text}</div>
        </div>
      </div>
    </span>
  )
}
