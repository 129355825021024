import { navigate, graphql } from 'gatsby'
import queryString from 'query-string'
import React, { useEffect, useReducer } from 'react'
import useForm from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import Button from '../../components/buttons/Button'
import ErrorComponent from '../../components/error/Error'
import FunnelSideBar from '../../components/funnelSideBar/FunnelSideBar'
import InputCesta from '../../components/inputCesta/InputCesta'
import Layout from '../../components/Layout'
import LayoutSelector from '../../components/layouts/layoutSelector/LayoutSelector'
import MainFormContainer from '../../components/layouts/mainFormContainer/MainFormContainer'
import SpinnerWithText from '../../components/spinnerWithText/SpinnerWithText'
import { t } from '../../i18n/index'
import logic from '../../logic'
import route from '../../utils/route'
import { alertProp } from '../../utils/swal'
import { SeoData } from '../../types/Seo'
import styles from './index.module.scss'
import SelectorFooterDesktop from '../../components/FunnelSelector/Footers/SelectorFooterDesktop'

interface NewPasswordData {
  password: string
  confirmPassword: string
}

const NewPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, t('validations.register.passwordLength'))
    .required(
      t('validations.register.required', { label: t('formLabels.password') })
    ),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      t('validations.register.must_match_passwords')
    ),
})

function reducer(state, action) {
  switch (action.type) {
    case 'CHECK_TOKEN':
      return {
        ...state,
        loading: true,
      }
    case 'CHECK_TOKEN_OK':
      return {
        ...state,
        loading: false,
        token: action.payload.token,
      }
    case 'CHECK_TOKEN_FAILED':
      return {
        ...state,
        tokenError: action.payload.error,
        loading: false,
        token: null,
      }
    case 'RESET_PASSWORD':
      return {
        ...state,
        loading: true,
      }
    case 'RESET_PASSWORD_OK':
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
      }
    case 'RESET_PASSWORD_FAILED':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        success: false,
      }
    case 'RESET_SUCCESS':
      return {
        ...state,
        success: false,
      }
    case 'RESET_ERROR':
      return {
        ...state,
        error: false,
        tokenError: false,
      }
    default:
      throw new Error('Unknown action')
  }
}

interface Props {
  data: {
    seoData: SeoData
  }
}

export default function Index({ data }: Props) {
  const { register, handleSubmit, errors } = useForm<NewPasswordData>({
    validationSchema: NewPasswordSchema,
  })

  const [state, dispatch] = useReducer(reducer, {
    tokenError: null,
    error: null,
    loading: false,
    success: false,
    token: null,
  })

  useEffect(() => {
    checkToken()
  }, [])

  const checkToken = () => {
    const params = queryString.parse(window.location.search)
    dispatch({
      type: 'CHECK_TOKEN',
    })
    logic
      .checkToken(params.token)
      .then(res => {
        dispatch({
          type: 'CHECK_TOKEN_OK',
          payload: {
            token: params.token,
          },
        })
      })
      .catch(err => {
        dispatch({
          type: 'CHECK_TOKEN_FAILED',
          payload: {
            error: err.message,
          },
        })
      })
  }

  const onSubmit = data => {
    dispatch({
      type: 'RESET_PASSWORD',
    })
    logic
      .resetPassword(data.password, data.confirmPassword, state.token)
      .then(res => {
        dispatch({
          type: 'RESET_PASSWORD_OK',
        })
      })
      .catch(err => {
        dispatch({
          type: 'RESET_PASSWORD_FAILED',
          payload: {
            error: err.message,
          },
        })
      })
  }

  if (state.success) {
    dispatch({
      type: 'RESET_SUCCESS',
    })
    Swal.fire(
      alertProp({
        type: 'success',
        title: 'Gracias!',
        text: 'Tu contraseña ha sido actualizada correctamente',
      })
    ).then(result => {
      if (result.value) {
        dispatch({
          type: 'RESET_ERROR',
        })
        navigate(route('login.index'))
      }
    })
  }

  if (state.tokenError) {
    dispatch({
      type: 'RESET_ERROR',
    })
    Swal.fire(
      alertProp({
        type: 'error',
        title: 'Oops...',
        text: t('login.link_no_valido'),
      })
    ).then(result => {
      if (result.value) {
        navigate(route('login.index'))
      }
    })
  }
  return (
    <Layout seoData={data.seoData} selector={true}>
      <LayoutSelector hideSelectorSteps={true} title="Cambia tu contraseña">
        <MainFormContainer>
          {!state.loading && (
            <React.Fragment>
              <form
                className={styles.new_password_form}
                onSubmit={handleSubmit(onSubmit)}>
                <InputCesta
                  label={<FormattedMessage id="formLabels.nueva_contraseña" />}
                  type="password"
                  name="password"
                  refe={register}
                  errors={errors}
                />
                <InputCesta
                  label={<FormattedMessage id="formLabels.confirm-password" />}
                  type="password"
                  name="confirmPassword"
                  refe={register}
                  errors={errors}
                />
                {state.error && <ErrorComponent message={state.error} />}
                <SelectorFooterDesktop continueButton />
              </form>
            </React.Fragment>
          )}
          {state.loading && <SpinnerWithText text={t('login.cargando')} />}
        </MainFormContainer>
        <FunnelSideBar />
      </LayoutSelector>
    </Layout>
  )
}

export const pageQuery = graphql`
  query newPasswordPageQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
